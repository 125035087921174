import Repository from "./Repository";

const resource = "/contact_message";

export default {
  sendmail(formdata) {
    return Repository.post(`${resource}`, formdata, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": "sk7oNt1I78l-vul0XzIyW-6vEIqqVqCjZ0u70_ys_yY",
        // 'WithCredentials': 'true',
        Authorization: "Basic TG91aXNhOjEyMzQ1"
      }
    });
  }
};
