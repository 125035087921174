import Repository from "./Repository";

const resource = "/api/content";

export default {
    get(type, id) {
        return Repository.get(`${resource}/${type}/${id}`);
    },
    searchSite(phrase) {
        return Repository.get(`/api/search/${phrase}`);
    },
};