import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";
import { VLazyImagePlugin } from "v-lazy-image";
import store from "./store";
import Vuelidate from "vuelidate";
import VueAnalytics from "vue-analytics";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import Meta from "vue-meta";
import vuescroll from "vuescroll";
import VModal from "vue-js-modal";
import { vsCollapse, vsIcon, vsSideBar, vsButton, vsPopup } from "vuesax";
import "vuesax/dist/vuesax.css";
import "material-icons/iconfont/material-icons.css";
import VScrollLock from "v-scroll-lock";
import vClickOutside from "v-click-outside";
import vuetify from "../src/plugins/vuetify";
import VueCookies from "vue-cookies";
import VueSocialSharing from "vue-social-sharing";
import "core-js/stable";
import "regenerator-runtime/runtime";

//import "../node_modules/nprogress/nprogress.css";
import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters);

Vue.use(vClickOutside);
Vue.use(VueCookies);

Vue.use(VScrollLock);
Vue.use(vsCollapse);
Vue.use(vsIcon);
Vue.use(vsSideBar);
Vue.use(vsButton);
Vue.use(vsPopup);

Vue.use(VModal, {});
// var SocialSharing = require("vue-social-sharing");

Vue.use(VLazyImagePlugin);

Vue.use(vuescroll, {
  ops: {}
});

const requireModal = require.context("./modals", false, /[A-Z]\w+\.(vue|js)$/);

requireModal.keys().forEach(fileName => {
  const componentConfig = requireModal(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.use(VueSocialSharing);
Vue.use(Meta);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(require("vue-moment"));

Vue.use(VueAnalytics, {
  id: "UA-11111" /* enter google analytics id here */,
  router
});

Vue.config.productionTip = false;

new Vue({
  router,
  BootstrapVue,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
