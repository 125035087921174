// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  theme: {
    primary: colors.blue.darken4,
    secondary: colors.amber.lighten2
  }
};

export default new Vuetify(opts);
