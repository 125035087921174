<template>
  <div>
    <span @click="show" class="is-small">view profile</span>
    <modal name="modal-display" width="100%" height="100%">hello, world!</modal>
  </div>
</template>

<script>
export default {
  methods: {
    show() {
      this.$modal.show("modal-display");
    },
    hide() {
      this.$modal.hide("modal-display");
    }
  }
};
</script>
<style scoped>
.is-small {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--amni-white);
  opacity: 1;
  text-transform: capitalize;
  font-size: 1rem;
  font-family: "Neue Helvetica World W01", sans-serif;
  z-index: 600;
}

.is-small:hover {
  text-decoration: underline;
}
</style>
