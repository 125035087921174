import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modal: {
      show: false,
      component: null,
      title: null,
      footer: false,
      hideLogo: false,
      closeBackdrop: true
    },
    openOPS: "",
    openCR: ""
  },
  mutations: {
    showModal(
      state,
      { title, component, footer, category, hideLogo, closeBackdrop, vendor }
    ) {
      state.modal.show = true;
      state.modal.title = title;
      state.modal.component = component;
      state.modal.footer = footer;
      state.modal.leadCategory = category;
      state.modal.hideLogo = hideLogo;
      state.modal.closeBackdrop = closeBackdrop;
      state.modal.vendor = vendor;
    },
    hideModal(state) {
      state.modal.show = false;
      state.modal.title = null;
      state.modal.component = null;
      state.modal.footer = false;
    },
    setOpenOps(state, { opsId }) {
      state.openOPS = opsId;
    },
    setOpenCr(state, { crId }) {
      state.openCR = crId;
    }
  },
  actions: {
    showModal(
      context,
      { title, component, footer, category, hideLogo, closeBackdrop, vendor }
    ) {
      context.commit("showModal", {
        title,
        component,
        footer,
        category,
        hideLogo,
        closeBackdrop,
        vendor
      });
    },
    hideModal(context) {
      context.commit("hideModal");
    },
    setOpenOps(context, { opsId }) {
      context.commit("setOpenOps", {
        opsId
      });
    },
    setOpenCr(context, { crId }) {
      context.commit("setOpenCr", {
        crId
      });
    }
  },
  modules: {}
});
