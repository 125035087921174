<template>
  <div id="app" data-app>
    <div class="page">
      <SideBar />

      <router-view />

      <!-- <v-snackbar
        bottom
        :multi-line="multiLine"
        value="updateExists"
        :timeout="-1"
      >
        This website uses cookies that provide necessary site functionality and
        improve your online experience.By continuing to use this website, you
        agree to the use of cookies. Our Cookies Policy provides more
        information about what cookies we use and how you can change them.
        <v-btn text @click="refreshApp">
          Update
        </v-btn>
      </v-snackbar> -->
    </div>
    <div>
      <v-bottom-sheet v-model="sheet" persistent>
        <v-sheet class="text-center bg-black text-white" height="150px">
          <v-btn class="mt-6 bg-orange-500 p-2" text @click="closeSheet()">
            CLOSE
          </v-btn>
          <div class="my-2 px-4 md:text-sm" v-html="cookieText"></div>
          <a class="text-orange-500" @click="closeSheet()" href="/cookies">
            Read Cookies Policy</a
          >
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import { BCarousel } from 'bootstrap-vue';

require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const ContentRepository = RepositoryFactory.get("content");

export default {
  components: {
    // TopNavBar: () => import("@/components/TopNavBar.vue"),
    // Modal: () => import("@/components/Modal.vue"),
    SideBar: () => import("@/components/SideBar.vue")
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Amni International"
    // all titles will be injected into this template
    // titleTemplate: '%s | My Awesome Webapp'
  },
  data() {
    return {
      open: false,
      slide: 0,
      sliding: null,
      isVisibility: false,
      isScroll: false,
      multiLine: true,
      sheet: true,
      cookieText:
        "This website uses cookies that provide necessary site functionality nd improve your online experience. By continuing to use this  website, you agree to the use of cookies. Our Cookies Policy provides more information about what cookies we use and how you can change them."
    };
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    },
    hideMenu() {
      this.show = false;
      this.isActive = false;
    },
    toggleClass() {
      this.isActive = !this.isActive;
    },
    closeSheet() {
      this.$cookies.set("policy", "false");
      this.sheet = false;
    },
    checkSheet() {
      if (this.$cookies.get("policy") == "false") {
        this.sheet = false;
      }
    },
    async getCookieNotification() {
      var type = "introductory";
      var id = "275";
      const { data } = await ContentRepository.get(type, id);
      //console.log("Overview:", data);
      if (data.length > 0) {
        this.cookieText = data[0].body[0].value;
      }
    }
    // lockScroll() {
    //   let body = document.getElementsByClassName("main");
    //   ////console.log("class name:", body);
    // }
  },
  created() {
    this.checkSheet();
    this.getCookieNotification();
  }
};
</script>

<style>
/* Social Icon styling */
.share {
  /* margin-top: 15px; */
  text-align: right;
}

.share a:hover {
  color: #4a4a4a;
}

.fa-facebook-square:hover {
  color: #3b5998;
}

.fa-twitter-square:hover {
  color: #00acee;
}

.fa-instagram:hover {
  color: #e1306c;
}

@import url("https://fonts.googleapis.com/css?family=Amiri&display=swap");
:root {
  /*=======  Fonts  ======*/
  --small-font: 0.875rem;
  --body-font: 1rem;

  /*=======  COLORS  =======*/
  --amni-primary: #fa7726;
  --amni-secondary: #030303;
  --amni-white: #ffffff;
  --amni-light-gray: #efeeee;
  --amni-dark-gray: #999999;
  --amni-very-dark: #4a4a4a;
  --amni-sidebar: #f9f8f8;
}

[class^="gb-"]:not(.gb-base-icon) {
  font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
}

/*=======  Basic Styling  =======*/
body {
  overflow-y: hidden;
}
#app {
  font-family: "Montserrat", sans-serif;
  font-size: 62.5%;
  background: var(--amni-sidebar);
  color: var(--amni-very-dark);
  overflow-y: hidden;
}
img {
  width: 100%;
  height: auto;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.125rem !important;
}
a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
  color: var(--amni-primary);
  transition: all 0.5s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: var(--amni-primary);
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: lighter;
  line-height: 1.75rem;
  font-size: 1rem;
  /* color: var(--amni-very-dark); */
}

.active {
  color: var(--amni-primary);
}

.active2 {
  color: var(--amni-white);
  font-size: 1.5rem;
}

.sidebar-active {
  color: var(--amni-primary);
  border-bottom: 2px solid var(--amni-primary);
  margin-bottom: 0.8rem;
  width: 0.5rem;
}

.career-head {
  font-family: "Open Sans", serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
}

/*========== CAROUSEL STYLES =============*/

.bg-animated {
  /* background: rgba(218, 218, 218, 0.4); */
  background: rgba(31, 31, 31, 0.4);
  position: absolute;
  left: auto;
  right: -8rem;
  bottom: 9rem;
  padding: 2rem;
  text-align: right;
}
.carousel-caption h1 {
  font-weight: bold;
}
.carousel-caption p {
  color: var(--amni-white);
  font-size: 1.2rem;
}

.carousel-caption .home {
  color: var(--amni-primary);
}

.img-fluid {
  height: 70vh;
}
.right-bar {
  border-right: 10px solid var(--amni-primary);
  padding: 1rem;
  font-size: 2rem;
}

.right-bar h1 {
  font-size: 2.5rem;
}

.right-bar p {
  font-size: 1.2rem;
}

.carousel-indicators li {
  width: 13px;
  height: 13px;
  opacity: 1;
}
.carousel-indicators .active {
  background-color: var(--amni-primary);
}

/*=======  Page Layout  =======*/
.page {
  background: var(--amni-light-gray);
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 100vh;
  overflow-y: hidden;
}

.zoomIn {
  animation-duration: 2s;
}

.slideIn {
  animation-duration: 1s;
}

.fadeIn {
  animation-duration: 1s;
  animation-delay: 1s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

#nprogress .bar {
  /* background: var(--amni-primary) !important; */
  background: rgba(153, 153, 153, 0.8) !important;
  height: 100vh !important;
}

/* #nprogress .peg {
  box-shadow: 0 0 10px var(--amni-primary), 0 0 5px var(--amni-primary) !important;
} */
#nprogress {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  /* z-index:450; */
  background: rgba(255, 255, 255, 0.8);
}

/* #nprogress .spinner-icon {
  border-top-color: var(--amni-primary) !important;
  border-left-color: var(--amni-primary) !important;
} */

/*========  Main Section  =========*/
main {
  flex: 1 1 90%;
  overflow-y: auto;
  background: var(--amni-sidebar);
  position: relative;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 1s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.content {
  padding: 4.0625rem 3.625rem;
}
/*======== ADHOC STYLING  =======*/

/*======= SECTION THREE ===========*/
.contact {
  background: linear-gradient(307.06deg, #a84000 -4.59%, #fa7726 94.63%);
  text-align: center;
}

.ct-btn {
  font-family: "Open Sans", sans-serif;
}

.ct-btn:hover {
  background: rgb(177, 71, 5) !important;
  color: #fff;
}

@media (max-width: 414px) {
  .content {
    order: 2;
    /* margin-top: 1rem; */
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
    height: auto;
  }
}
/*=======  Media Query  =======*/

@media (max-width: 480px) {
  .page {
    display: flex;
    flex-direction: column;
  }

  .career-head {
    font-family: "Open Sans", serif;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2rem;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-weight: lighter;
    line-height: 1.5rem;
    font-size: 0.875rem;
    color: var(--amni-very-dark);
  }

  .content {
    padding: 1.5rem;
  }
  /* Main Section */
}

.shadow {
  box-shadow: 0px 1.74783px 3.49565px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .page {
    display: flex;
    flex-direction: column;
  }
  .bg-animated {
    bottom: 3rem;
    padding: 1rem;
    right: -5rem;
  }
  .right-bar p {
    font-size: 0.8rem;
  }
  .right-bar h1 {
    font-size: 1.6rem;
  }
  .right-bar {
    border-right: 7px solid var(--amni-primary);
    padding: 1rem;
    font-size: 2rem;
  }
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    opacity: 1;
  }
  .content {
    padding: 1.5rem;
  }
}
</style>
