import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
//import NProgress from "nprogress";

// // import Home from "../views/Home.vue";
// const options = {
//     latencyThreshold: 1000, // Number of ms before progressbar starts showing
//     router: true, // Show progressbar when navigating routes
//     http: true // Show progressbar when doing Axios.http or Vue.http
// };

//Vue.use(NProgress);
Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    name: "home",
    component: function() {
      return import(/* webpackChunkName: "group-home" */ "../views/Home.vue");
    }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    }
  },
  {
    path: "/chairman",
    name: "chairman",
    component: function() {
      return import(
        /*webpackChunkName: "group-about" */
        "../views/Chairman.vue"
      );
    }
  },
  {
    path: "/history",
    name: "history",
    component: function() {
      return import(
        /*webpackChunkName: "group-about" */
        "../views/History.vue"
      );
    }
  },
  {
    path: "/board",
    name: "board",
    component: function() {
      return import(/*webpackChunkName: "group-about" */ "../views/Board.vue");
    }
  },
  {
    path: "/bod",
    name: "bod",
    component: function() {
      return import(/*webpackChunkName: "group-about" */ "../views/Bod.vue");
    }
  },
  {
    path: "/senior",
    name: "senior",
    component: function() {
      return import(/*webpackChunkName: "group-about" */ "../views/Senior.vue");
    }
  },
  {
    path: "/expert",
    name: "expert",
    component: function() {
      return import(/*webpackChunkName: "group-about" */ "../views/Expert.vue");
    }
  },
  {
    path: "/operations",
    name: "operations",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/Operations.vue"
      );
    }
  },
  {
    path: "/opdetail/:nodeid",
    name: "opdetail",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/OPDetail.vue"
      );
    },
    props: route => {
      route.params.nodeid;
    }
  },
  {
    path: "/cen",
    name: "cen",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/Cen.vue"
      );
    }
  },
  {
    path: "/oko",
    name: "oko",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/Oko.vue"
      );
    }
  },
  {
    path: "/tubu",
    name: "tubu",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/Tubu.vue"
      );
    }
  },
  {
    path: "/ima",
    name: "ima",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/Ima.vue"
      );
    }
  },
  {
    path: "/corporate",
    name: "corporate",
    component: function() {
      return import(
        /*webpackChunkName: "group-corporate" */
        "../views/Corporate.vue"
      );
    }
  },
  {
    path: "/crdetail/:nodeid",
    name: "crdetail",
    component: function() {
      return import(
        /*webpackChunkName: "group-operations" */
        "../views/CRDetail.vue"
      );
    },
    props: route => {
      route.params.nodeid;
    }
  },
  {
    path: "/safety",
    name: "safety",
    component: function() {
      return import(
        /*webpackChunkName: "group-corporate" */
        "../views/Safety.vue"
      );
    }
  },
  {
    path: "/community",
    name: "community",
    component: function() {
      return import(
        /*webpackChunkName: "group-corporate" */
        "../views/Community.vue"
      );
    }
  },
  {
    path: "/nigeria",
    name: "nigeria",
    component: function() {
      return import(
        /*webpackChunkName: "group-corporate" */
        "../views/Nigeria.vue"
      );
    }
  },
  {
    path: "/sustain",
    name: "sustain",
    component: function() {
      return import(
        /*webpackChunkName: "group-corporate" */
        "../views/Sustain.vue"
      );
    }
  },
  {
    path: "/contactus",
    name: "contactus",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Address.vue");
    }
  },
  {
    path: "/inflash",
    name: "inflash",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Inflash.vue");
    }
  },
  {
    path: "/careers",
    name: "careers",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Careers.vue");
    }
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: function() {
      return import(
        /*webpackChunkName: "group-home" */
        "../views/Disclaimer.vue"
      );
    }
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Sitemap.vue");
    }
  },
  {
    path: "/policy",
    name: "policy",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Policy.vue");
    }
  },
  {
    path: "/cookies",
    name: "cookies",
    component: function() {
      return import(
        /*webpackChunkName: "group-home" */ "../views/CookiesPolicy.vue"
      );
    }
  },
  {
    path: "/sendmail",
    name: "sendmail",
    component: function() {
      return import(
        /*webpackChunkName: "group-home" */
        "../views/SendMail.vue"
      );
    }
  },
  {
    path: "/plan",
    name: "plan",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Plan5.vue");
    }
  },
  {
    path: "/gallery",
    name: "gallery",
    component: function() {
      return import(/*webpackChunkName: "group-home" */ "../views/Gallery.vue");
    }
  },
  {
    path: "/news",
    name: "news",
    component: function() {
      return import(/*webpackChunkName: "group-media" */ "../views/News.vue");
    }
  },
  {
    path: "/sponsor",
    name: "sponsor",
    component: function() {
      return import(
        /*webpackChunkName: "group-media" */
        "../views/Sponsor.vue"
      );
    }
  },
  {
    path: "/document",
    name: "document",
    component: function() {
      return import(
        /*webpackChunkName: "group-media" */
        "../views/Document.vue"
      );
    }
  },
  {
    path: "/insight",
    name: "insight",
    component: function() {
      return import(
        /*webpackChunkName: "group-media" */
        "../views/Insights.vue"
      );
    }
  },
  {
    path: "/search",
    name: "search",
    component: function() {
      return import("../views/SearchPage.vue");
    }
  },
  {
    path: "/press",
    name: "press",
    component: function() {
      return import(/*webpackChunkName: "group-media" */ "../views/Press.vue");
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
});

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   // window.scrollTo(0, 0);
//   if (to.path) {
//     NProgress.start();
//   }
//   next();
// });

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done();
// });

export default router;
