import ContentRepository from "./contentRepository";
import SendmailRepository from "./sendmailRepository";

const repositories = {
  content: ContentRepository,
  sendmail: SendmailRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
